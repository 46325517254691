import React from 'react';
import econtract_1 from '../../assets/images/CPQ/econtract_1.png'
import econtract_2 from '../../assets/images/CPQ/econtract_2.png'
import econtract_3 from '../../assets/images/CPQ/econtract_3.png'
export const Banner30DataSource = {
  wrapper: { className: 'banner3 llolraks3nb-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: (
          <span role="img" aria-label="donut">
            基于 BillingMore 明智签程™️ 数字云办公体系
          </span>
        ),
      },
      {
        name: 'slogan',
        className: 'banner3-slogan llotlytard-editor_css',
        children: 'SmartDeal EContract 数字化签约流程管理解决方案',
        texty: false, // 控制children内容逐个动画效果
      },
      {
        name: 'name',
        className: 'banner3-name llotn1d3xe-editor_css',
        children: (
          <p>
            SmartDeal EContract 不仅仅提供了数字化合同的自动生成，并配合企业自身可制化特性，为企业提供合同条款动态匹配、自定义审批流转、合同生命周期管理能力。
          </p>
        ),
      },
      {
        name: 'button',
        className: 'banner3-button llotnh6gz6-editor_css',
        children: (
          <p>立即试用</p>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: (
          <br />
        ),
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5k4cw29-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: econtract_1,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>一键生成满足企业所需的标准化合同</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        通过标准的企业可制化合同模版，并结合可根据合同类型、售卖产品类型动态匹配合同条款规则，从而自动匹配生成满足当前企业服务所需的客制化自定义合同，极大减少了合同制作、法务审核的时间。
      </p>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper llon5mkejll-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: econtract_2,
  },
  textWrapper: { className: 'content2-text', md: 13, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <p>自由的合同审核链路配置，降低合同合规审核成本</p>
    ),
  },
  content: {
    className: 'content2-content',
    children: '企业可根据合同类型、折扣范围、付款方式、售卖产品类型等维度设定自定义的合同审批流程，针对客户需要修改条款等情况下的非标准合同，SmartDeal EContract能智能基于标准合同模版高亮所有合同修改信息，帮助审核人员快速评估合同有效性，减少低效的比对查询时间。',
  },
};
export const Feature11DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5npwhvl-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: econtract_3,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>客户浏览记录实时提醒，把握客户兴趣黄金时间</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        自动获取客户浏览时间和时长信息，帮助销售快速识别和优先处理高意向客户来产生更多收入。钉钉、企业微信、飞书、短信等多渠道客户浏览提醒，结合客户意向分析，可以帮助企业深入洞察并改善销售人员谈判、方案问题，提升签约率。
      </p>
    ),
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content llon5pdall-editor_css',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <p>销售流程全面赋能提升</p>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '倍' },
              toText: true,
              children: '3',
            },
            children: { className: 'feature6-text', children: '客户体验提升' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '100',
            },
            children: { className: 'feature6-text', children: '合同签署速度提升' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '50',
            },
            children: { className: 'feature6-text', children: '合规风险减少' },
          },
        ],
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: {
    className: 'home-page-wrapper content9-wrapper llnh5xqeajp-editor_css',
  },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>好评如潮</p>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <p>绵绵</p>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <p>后陆网络</p>
            ),
          },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: (
              <p>SmartDeal的便捷显而易见</p>
            ),
          },
          content: {
            className: 'block-content',
            children:
              '明智签程的使用很让人惊喜，原本的期望只是可以快速建立引导式报价，便于对客的展示沟通，没想到在深入了解并使用以后，反而帮助我们更快的规范化了整体产品SKU的构成，并直观展示了各销售环节的转化跟进数据构成。',
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: { className: 'block-name', children: 'LEO' },
          post: { className: 'block-post', children: '鸡同鸭酱文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '降本增效的改变超出预期',
          },
          content: {
            className: 'block-content',
            children:
              '经过近半年的定制化深度使用，BillingMore在助力中台管理效能提升的目标之上，很好的串联了我们团队的各个模块，达成了超出原有预期的管理降本改善。',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <p>CICI</p>
            ),
          },
          post: { className: 'block-post', children: '菜包包文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '对客方案提报从未变得如此简单',
          },
          content: {
            className: 'block-content',
            children:
              '经过近半年的定制化深度使用，BillingMore在助力中台管理效能提升的目标之上，很好的串联了我们团队的各个模块，达成了超出原有预期的管理降本改善。',
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>获取属于你的数字化赋能体验</p>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <p>
            联系我们，获得涵盖客户管理、报价签约、运营执行、中台管理等领域的完整数字化转型方案
          </p>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <p>专业产品专家一对一客制化制定方案</p>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        children: (
          <p>联系我们</p>
        ),
      },
    },
  },
};
