import React from 'react';
import crm_1 from '../../assets/images/CPQ/crm_1.png'
import crm_2 from '../../assets/images/CPQ/crm_2.png'
import crm_3 from '../../assets/images/CPQ/crm_3.png'
export const Banner30DataSource = {
  wrapper: { className: 'banner3 llolraks3nb-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: (
          <span role="img" aria-label="donut">
            基于 BillingMore 明智签程™️ 数字云办公体系
          </span>
        ),
      },
      {
        name: 'slogan',
        className: 'banner3-slogan llotlytard-editor_css',
        children: 'SmartDeal CRM 客户跟进追踪管理解决方案',
        texty: false, // 控制children内容逐个动画效果
      },
      {
        name: 'name',
        className: 'banner3-name llotn1d3xe-editor_css',
        children: (
          <p>
            SmartDeal CRM 打通了商务跟进客户的各个环节，并与飞书、企业微信等办公套件深入集成整合，不依赖人工信息录入，提供了强大的商务行为能力及客户关系管理能力。
          </p>
        ),
      },
      {
        name: 'button',
        className: 'banner3-button llotnh6gz6-editor_css',
        children: (
          <p>立即试用</p>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: (
          <br />
        ),
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5k4cw29-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: crm_1,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>直观的客户关系管理看板</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        通过集成式的客户关系管理看板，涵盖了客户从建立开始的全部留存数据，并可根据客户意向度、预计回款时间、客情关系、客户等级等维度快速匹配筛选，并建立自定义数据分析看板，企业可以快速定位与评估当前客户池存量，并快速评估客户跟进健康度，保障商机有效推进。
      </p>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper llon5mkejll-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: crm_2,
  },
  textWrapper: { className: 'content2-text', md: 13, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <p>自由的客户关系代办、留言系统</p>
    ),
  },
  content: {
    className: 'content2-content',
    children: '集成式的客户关系管理看板深入整合了代办留言系统，不同角色岗位可以在统一平台完成跟进提醒、续约协作工作，并可在多渠道对相关人员进行实时推送通知提醒，并提供实时反馈处理方案，在确保沟通及时的同时、也保障了对客信息的完整留存。',
  },
};
export const Feature11DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5npwhvl-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: crm_3,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>与第三方办公系统深度整合</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        SmartDeal CRM 实现了与飞书、企业微信、钉钉的深度整合，可匹配接入企业原有审批流程，不改变原有工作习惯，一键同步妙记录音、生成商务拜访日历，帮助企业在不额外增加使用成本的前提下，建立完整客户关系数据闭环。
      </p>
    ),
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content llon5pdall-editor_css',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <p>销售流程全面赋能提升</p>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '80',
            },
            children: { className: 'feature6-text', children: '客户数据流失减少' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'X' },
              toText: true,
              children: '3',
            },
            children: { className: 'feature6-text', children: '管理决策速度提升' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'X' },
              toText: true,
              children: '10',
            },
            children: { className: 'feature6-text', children: '团队协作能力提升' },
          },
        ],
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: {
    className: 'home-page-wrapper content9-wrapper llnh5xqeajp-editor_css',
  },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>好评如潮</p>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <p>绵绵</p>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <p>后陆网络</p>
            ),
          },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: (
              <p>SmartDeal的便捷显而易见</p>
            ),
          },
          content: {
            className: 'block-content',
            children:
              '明智签程的使用很让人惊喜，原本的期望只是可以快速建立引导式报价，便于对客的展示沟通，没想到在深入了解并使用以后，反而帮助我们更快的规范化了整体产品SKU的构成，并直观展示了各销售环节的转化跟进数据构成。',
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: { className: 'block-name', children: 'LEO' },
          post: { className: 'block-post', children: '鸡同鸭酱文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '降本增效的改变超出预期',
          },
          content: {
            className: 'block-content',
            children:
              '经过近半年的定制化深度使用，BillingMore在助力中台管理效能提升的目标之上，很好的串联了我们团队的各个模块，达成了超出原有预期的管理降本改善。',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <p>CICI</p>
            ),
          },
          post: { className: 'block-post', children: '菜包包文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '对客方案提报从未变得如此简单',
          },
          content: {
            className: 'block-content',
            children:
              '经过近半年的定制化深度使用，BillingMore在助力中台管理效能提升的目标之上，很好的串联了我们团队的各个模块，达成了超出原有预期的管理降本改善。',
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>获取属于你的数字化赋能体验</p>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <p>
            联系我们，获得涵盖客户管理、报价签约、运营执行、中台管理等领域的完整数字化转型方案
          </p>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <p>专业产品专家一对一客制化制定方案</p>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        children: (
          <p>联系我们</p>
        ),
      },
    },
  },
};
