import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import { getChildrenToRender } from '../utils/utils';

const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
  state = {
    phoneOpen: undefined,
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({ phoneOpen });
  };

  // 页面刷新时根据路由信息处理当前选中菜单
  curMenu = ['item0']
  handleCurMenu = () => {
    const locationHref = window.location.href
    let curMenu = 'item0'
    if (locationHref.includes('/SmartDealCPQ')) {
      curMenu = 'sub0'
    } else if (locationHref.includes('/SmartSignEContract')) {
      curMenu = 'sub1'
    } else if (locationHref.includes('/SmartDealCRM')) {
      curMenu = 'sub2'
    } else if (locationHref.includes('/News')) {
      curMenu = 'item2'
    } else if (locationHref.includes('/AboutUs')) {
      curMenu = 'item3'
    } else {
      curMenu = 'item0'
    }
    this.curMenu = [curMenu]
  }

  // 切换菜单时页面滚动到顶部
  scrollTop = () => {
    const height = document.documentElement.scrollTop || document.body.scrollTop;
    //自定义高度
    if(height > 0){
      //浏览器页面每一次重绘的时候调用
      window.requestAnimationFrame(this.scrollTop);
      //将内容滚动到指定坐标
      window.scrollTo(0,height-height/8);
    }
  }

  render() {
    this.handleCurMenu()
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header3-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header3-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <Link {...childItem} to={childItem.href}>
                  {childItem.children.map(getChildrenToRender)}
                </Link>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <Link {...a} to={item.children.href} className={`header3-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </Link>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <a href="/" onClick={this.scrollToTop}>
              <img width="100%" src={dataSource.logo.children} alt="img" />
            </a>
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={this.curMenu}
              theme="light"
              onSelect={this.scrollTop}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header3;
