import React from 'react';
import { Row, Col } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../../utils/utils';
import * as Api from '../../api/api'

class Content12 extends React.PureComponent {
  state = {
    newsList: []
  }
  componentDidMount() {
    this.getNewsList()
  }
  // 获取新闻数据列表
  getNewsList = () => {
    let param = {
      curr: 1,
      limit: 6,
      sortOrder: "",
      sortField: ""
    }
    this.setState({ newsList: [] })
    Api.GetPageNewsList(param).then(res => {
      if (res.code === 200 && res.data) {
        this.setState({ newsList: res.data })
      }
    })
  }

  // 处理点击新闻图片
  handleClickImg = (event) => {
    if (event.url) {
      window.open(event.url, '_blank');
    } else if(event.id) {
      window.location.href = `${window.location.origin}/NewsDetail?${event.id}`
    }
  }

  getChildrenToRender = (data) =>
    data.map((item, index) => {
      const { newsList } = this.state
      const imgSrc = newsList[index] ? `https://billingmore.oss-cn-shanghai.aliyuncs.com/${newsList[index].banner || ''}` : ''
      return (
        <Col key={item.name} {...item}>
          <div {...item.children.wrapper}>
            <span {...item.children.img}>
              <img src={imgSrc} alt="" className='newsImg' onClick={() => this.handleClickImg(newsList[index])} />
            </span>
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getChildrenToRender(
      dataSource.block.children
    );
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack
            className={`content-template ${props.className}`}
            {...dataSource.OverPack}
          >
            <TweenOneGroup
              component={Row}
              key="ul"
              enter={{
                y: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
              }}
              leave={{ y: '+=30', opacity: 0, ease: 'easeOutQuad' }}
              {...dataSource.block}
            >
              {childrenToRender}
            </TweenOneGroup>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content12;
