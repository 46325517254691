import request from '../utils/request'
import api from './index'

// 申请试用-提交
export function SaveCpqCompany(data) {
  return request({
    url: api.shoomeOAUrl + api['SaveCpqCompany'],
    method: 'post',
    data,
  })
}

// 获取新闻list
export function GetPageNewsList(data) {
  return request({
    url: api.shoomeOAUrl + api['GetPageNewsList'],
    method: 'post',
    data,
  })
}

// 获取新闻详情
export function GetNewsById(id) {
  return request({
    url: api.shoomeOAUrl + api['GetNewsById'] + id,
    method: 'get'
  })
}