import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Button } from 'antd';
import { getChildrenToRender } from '../utils/utils';
import TrialModal from './TrialModal'

class Content11 extends React.PureComponent {
  // 打开申请试用modal PC版打开modal-确定后关闭modal，移动端跳转页面-确定后跳转首页
  openTrial = () => {
    sessionStorage.setItem('trialType', '联系我们')
    if (this.props.isMobile) {
      window.location.href = "/Trial"
    } else {
      this.TrialRef.setIsModalOpen()
    }
  }
  
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <>
        <OverPack {...props} {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            leaveReverse
            key="page"
            delay={[0, 100]}
            {...dataSource.titleWrapper}
          >
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          <TweenOne
            key="button"
            style={{ textAlign: 'center' }}
            {...dataSource.button}
            animation={{ y: 30, opacity: 0, type: 'from', delay: 300 }}
          >
            <Button {...dataSource.button.children.a} onClick={this.openTrial}>
              {dataSource.button.children.a.children}
            </Button>
          </TweenOne>
        </OverPack>
        <TrialModal ref={e => this.TrialRef = e} isMobile={this.props.isMobile} />
      </>
    );
  }
}

export default Content11;
