import React from 'react';
import icon_1 from '../assets/images/icon/icon_1.png'
import icon_2 from '../assets/images/icon/icon_2.png'
import icon_3 from '../assets/images/icon/icon_3.png'
import head1 from '../assets/images/head/mia.png'
import head2 from '../assets/images/head/leo.png'
import head3 from '../assets/images/head/cici.png'
import BillingMore_Landscape from '../assets/images/logo/BillingMore_Landscape.png'
import BillingMore_Landscape_white from '../assets/images/logo/BillingMore_Landscape_white.png'
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children: BillingMore_Landscape,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>主页</span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/Solution',
          children: [
            {
              children: (
                <span>解决方案</span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub nav-sub-item',
            children: {
              href: '/SmartDealCPQ',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children: (
                    <div>
                      <img src={icon_1} alt=''></img>
                    </div>
                  ),
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'SmartDeal CPQ',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '报价跟进管理',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub nav-sub-item',
            children: {
              href: '/SmartSignEContract',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children: (
                    <div>
                      <img src={icon_2} alt=''></img>
                    </div>
                  ),
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'SmartDeal EContract',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '签约流程管理',
                },
              ],
            },
          },
          {
            name: 'sub2',
            className: 'item-sub nav-sub-item',
            children: {
              href: '/SmartDealCRM',
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children: (
                    <div>
                      <img src={icon_3} alt=''></img>
                    </div>
                  ),
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: 'SmartDeal CRM',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '客户关系管理',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/News',
          children: [
            {
              children: (
                <span>资讯中心</span>
              ),
              name: 'text',
            },
          ],
        },
      },
      // {
      //   name: 'item3',
      //   className: 'header3-item',
      //   children: {
      //     href: '/AboutUs',
      //     children: [
      //       {
      //         children: (
      //           <span>关于我们</span>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      // },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content90DataSource = {
  wrapper: {
    className: 'home-page-wrapper content9-wrapper llnh5xqeajp-editor_css',
  },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>好评如潮</p>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children: (
              <div>
                <img src={head1} alt=''></img>
              </div>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <p>绵绵</p>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <p>后陆网络</p>
            ),
          },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: (
              <p>SmartDeal的便捷显而易见</p>
            ),
          },
          content: {
            className: 'block-content',
            children: '明智签程的使用很让人惊喜，原本的期望只是可以快速建立引导式报价，便于对客的展示沟通，没想到在深入了解并使用以后，反而帮助我们更快的规范化了整体产品SKU的构成，并直观展示了各销售环节的转化跟进数据构成。',
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children: (
              <div>
                <img src={head2} alt=''></img>
              </div>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: { className: 'block-name', children: 'LEO' },
          post: { className: 'block-post', children: '鸡同鸭酱文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '降本增效的改变超出预期',
          },
          content: {
            className: 'block-content',
            children: '经过近半年的定制化深度使用，BillingMore在助力中台管理效能提升的目标之上，很好的串联了我们团队的各个模块，达成了超出原有预期的管理降本改善。',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children: (
              <div>
                <img src={head3} alt=''></img>
              </div>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <p>CICI</p>
              </span>
            ),
          },
          post: { className: 'block-post', children: '菜包包文化传播' },
          time: { className: 'block-time', children: '' },
          title: {
            className: 'block-title',
            children: '对客方案提报从未变得如此简单',
          },
          content: {
            className: 'block-content',
            children: 'BillingMore 的引导式报价系统很有意思，在对客展示的报价单中集成展示了所受服务的更多信息，如产品介绍、应用案例，极大降低了对客报价方案文档的制作时间，甚至有时候可以与用户面对面，现场调整报价方案，及时确认客户需求。',
          },
        },
      },
    ],
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>获取属于你的数字化赋能体验</p>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <p>
            联系我们，开启涵盖客户管理、报价签约、运营执行、中台管理等领域的完整数字化转型可能
          </p>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <p>专业产品专家一对一客制化制定方案</p>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        children: (
          <p>联系我们</p>
        ),
      },
    },
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: BillingMore_Landscape_white,
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '赋能企业数字化转型的数据科技公司',
            },
            {
              name: 'content1',
              children: (
                <>
                  商务合作：
                  <a href="tel:13917671012">139 1767 1012</a>
                </>
              )
            },
            {
              name: 'content2',
              children: (
                <div>
                  <span>技术支持：</span>
                  <a href="mailto:develop@shoome.cn">develop@shoome.cn</a>
                </div>
              )
            },
            {
              name: 'content3',
              children: (
                <div>
                  <span>服务反馈：</span>
                  <a href="mailto:feedback@shoome.cn">feedback@shoome.cn</a>
                </div>
              )
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '解决方案' },
        childWrapper: {
          children: [
            // { href: `${window.location.origin}/SmartDealCPQ`, name: 'link0', children: 'SCloud CPQ报价系统' },
            { href: '/SmartDealCPQ', name: 'link1', children: 'SmartDeal CPQ' },
            { href: '/SmartSignEContract', name: 'link2', children: 'SmartDeal EContract' },
            { href: '/SmartDealCRM', name: 'link3 ', children: 'SmartDeal CRM' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于我们' },
        childWrapper: {
          children: [
            { href: '/News', name: 'link0', children: '资讯中心' },
            { href: '/News', name: 'link1', children: '关于我们' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright block',
    children: (
      <>
        <span>
          © 2023 上海数骞数据科技有限公司 版权所有 保留一切权利
        </span>
        <a href="https://beian.miit.gov.cn/" rel="noopener noreferrer" target="_blank">
          沪ICP备2023022850号-1
        </a>
      </>
    ),
  },
};
