import React from 'react';
import * as Api from '../../api/api'

class Contetn extends React.PureComponent {
  state = {
    id: 0,
    createUserName: '',
    createTime: '',
    title: '',
    context: ''
  }

  componentDidMount() {
    this.getNewsList()
  }

  // 获取新闻详情
  getNewsList = () => {
    const hrefStr = window.location.href
    const id = hrefStr.split('?')[1]
    Api.GetNewsById(id).then(res => {
      if (res.code === 200 && res.data) {
        this.setState({
          id: res.data.id,
          title: res.data.title,
          createUserName: res.data.createUserName,
          createTime: res.data.createTime,
          context: res.data.context,
        })
      }
    })
  }

  render() {
    const { title, context, createUserName, createTime } = this.state
    const Divider = createUserName && createTime ? '|' : ''
    return (
      <div className='newsDetailWrapper'>
        <div className="newsTitle">{title}</div>
        <div className="newsDetailTime">{createUserName} {Divider} { createTime }</div>
        <div dangerouslySetInnerHTML={{ __html: context }} ></div>
      </div>
    );
  }
}

export default Contetn;
