import React from 'react';
export const Banner30DataSource = {
  wrapper: { className: 'banner3 llw2viekjm-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper llw2wfj77j6-editor_css',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan llw2wvltceg-editor_css',
        children: '新闻资讯',
        texty: false,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: (
          <span>
            <p>第一时间获得BillingMore的最新动态</p>
          </span>
        ),
      },
    ],
  },
};
