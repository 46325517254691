/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';

import Banner3 from './Banner3';
import Footer1 from '../../components/Footer1';
import Contetn from './Contetn';

import { Banner30DataSource } from './data.source';
import { Footer11DataSource } from '../../utils/data.source';
import './less/antMotionStyle.less';

export default class Home extends React.Component {
  render() {
    return (
      <div className="templates-wrapper" >
        <Banner3
          id="Banner3_0"
          key="Banner3_0"
          dataSource={Banner30DataSource}
          isMobile={this.props.isMobile}
        />
        <Contetn
          isMobile={this.props.isMobile}
        />
        <Footer1
          id="Footer1_1"
          key="Footer1_1"
          isMobile={this.props.isMobile}
          dataSource={Footer11DataSource}
        />
      </div>
    );
  }
}
