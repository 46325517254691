import React from 'react';
import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import * as Api from '../../api/api'

class Contetn extends React.PureComponent {
  state = {
    newsList: [],
    total: 1, // 数据总数
    curr: 1, // 当前页数
    limit: 10, // 每页条数
  }

  componentDidMount() {
    this.getNewsList()
  }

  // 获取新闻数据列表
  getNewsList = () => {
    let param = {
      curr: this.state.curr,
      limit: this.state.limit,
      sortOrder: "",
      sortField: ""
    }
    this.setState({
      total: 1,
      newsList: [],
    })
    Api.GetPageNewsList(param).then(res => {
      if (res.code === 200 && res.data) {
        this.setState({
          total: res.count,
          newsList: res.data,
        })
      }
    })
  }

  // 分页改变获取对应分页下的新闻数据
  onChange = async (page, limit) => { 
    await this.setState({
      curr: page,
      limit: limit,
    })
    this.getNewsList()
  }

  render() {
    const { newsList, total, curr } = this.state
    const childrenList = newsList.map(item => {
      let imgSrc = item.banner ? `https://billingmore.oss-cn-shanghai.aliyuncs.com/${item.banner}` : ''
      let titleHtml = ``
      if (item.url) {
        titleHtml = <a target="_blank" rel="noopener noreferrer" className="newsTitle" href={item.url} >{ item.title }</a>
      } else {
        titleHtml = <Link className="newsTitle" to={`/NewsDetail?${item.id}`}>{ item.title }</Link>
      }
      return (
        <div key={item.id}>
          <div className='news_con'>
            <div className='news_left'>
              { titleHtml }
              <div className="newsDetail">{ item.detail }</div>
              <div className="newsTime">{ item.createTime }</div>
            </div>
            <div className='news_img'>
              <img src={imgSrc} alt="" />
            </div>
          </div>
          <div className='divider'></div>
        </div>
      )
    })
    return (
      <div className='newsWrapper'>
        {childrenList}
        <Pagination
          total={total}
          showQuickJumper
          defaultCurrent={curr}
          onChange={this.onChange}
          locale={{ items_per_page: '/ 页', jump_to: '跳至', page: '页' }}
        />
      </div>
    );
  }
}

export default Contetn;
