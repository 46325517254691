import React from 'react';
import { Button, notification } from 'antd';
import * as Api from '../../api/api'
import './less/Trial.less'
import Landscape from '../../assets/images/logo/BillingMore_Landscape.png'

// 申请试用modal
class Trial extends React.PureComponent {
  state = {
    okLoading: false,
    contactsName: '', // 姓名
    position: '', // 职位
    companyName: '', // 公司名称
    phone: '', // 联系方式
  }

  // 表单输入修改state的值
  changeFromData = (label, event) => {
    this.setState({[label]: event.target.value})
  }

  // 确定提交
  submitFrom = () => {
    const { isMobile } = this.props
    const { contactsName, position, companyName, phone } = this.state
    const type = sessionStorage.getItem('trialType') || '联系我们' // 打开申请试用时存储type
    if (!contactsName) {
      alert('请完善信息后提交')
      return
    }
    if (contactsName.length > 30 || position.length > 30) {
      alert('前两项最多30字')
      return
    }
    if (companyName.length > 50) {
      alert('公司名称最多50字')
      return
    }
    if (phone.length > 20) {
      alert('电话最多20字')
      return
    }
    this.setState({okLoading: true})
    let param = { contactsName, position, companyName, phone, type }
    Api.SaveCpqCompany(param).then(res => {
      if (res.code === 200) {
        const setTime = isMobile ? 3000 : 10
        notification.success({
          message: '提交成功！',
          description:  '稍后将会有工作人员与您沟通！',
        });
        setTimeout(() => {
          sessionStorage.removeItem('trialType')
          this.setState({ okLoading: false })
          if (isMobile) {
            window.location.href = "/"
          } else {
            this.props.onCancel()
          }
        }, setTime);
      }
    }).catch(() => {
      notification.error({
        message: '提交失败！',
        description:  '',
      });
      this.setState({ okLoading: false })
    })
  }

  render() {
    return (
      <div className="trial_box">
        <div className="trial_icon">
          <img src={Landscape} alt="" />
        </div>
        <div className="trial_title">申请试用</div>
        <div className="input_box">
          <div className="input_con">
            <input type='text' onChange={e => this.changeFromData('contactsName', e)} className='input' />
            <span className='inputTitle'>姓名</span>
          </div>
          <div className="input_con">
            <input type='text' onChange={e => this.changeFromData('position', e)} className='input' />
            <span className='inputTitle'>职位</span>
          </div>
          <div className="input_con">
            <input type='text' onChange={e => this.changeFromData('companyName', e)} className='input' />
            <span className='inputTitle'>公司名称</span>
          </div>
          <div className="input_con">
            <input type='text' onChange={e => this.changeFromData('phone', e)} className='input' />
            <span className='inputTitle'>联系方式</span>
          </div>
          <Button type="primary" className='llotnh6gz6-editor_css' size={'large'} loading={this.state.okLoading} onClick={this.submitFrom}>
            确定
          </Button>
        </div>
      </div>
    );
  }
}

export default Trial;