import { enquireScreen } from 'enquire-js';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"

import News from './Page/News'
import Home from './Page/Home'
import Trial from './Page/Trial'
import AboutUs from './Page/AboutUs'
import Nav3 from './components/Nav3';
import NewsDetail from './Page/NewsDetail'
import SmartDealCPQ from './Page/SmartDealCPQ'
import SmartDealCRM from './Page/SmartDealCRM'
import SmartSignEContract from './Page/SmartSignEContract'

import { Nav30DataSource } from './utils/data.source';
import './Style/antMotionStyle.less';

let isMobile;
enquireScreen(b => isMobile = b);

class App extends Component {
  state = {
    isMobile,
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const { isMobile } = this.state
    // 处理当前path下页面是否包含nav导航
    const pathname = ['/Trial'].includes(window.location.pathname)
    const Nav = <Nav3 id="Nav3_0" key="Nav3_0" isMobile={isMobile} dataSource={Nav30DataSource} />
    return (
      <>
        <Router>
          { pathname ? <></> : Nav }
          <Routes>
            <Route exact path="/" element={<Home isMobile={isMobile}/>} />
            <Route exact path="/SmartDealCPQ" element={<SmartDealCPQ isMobile={isMobile} />} />
            <Route exact path="/SmartSignEContract" element={<SmartSignEContract isMobile={isMobile} />} />
            <Route exact path="/SmartDealCRM" element={<SmartDealCRM isMobile={isMobile} />} />
            <Route exact path="/News" element={<News isMobile={isMobile}/>} />
            <Route exact path="/NewsDetail" element={<NewsDetail isMobile={isMobile}/>} />
            <Route exact path="/AboutUs" element={<AboutUs isMobile={isMobile} />} />
            <Route exact path="/Trial" element={<Trial isMobile={isMobile} />} />
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
