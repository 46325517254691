/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';

import Content13 from './Content13';
import Footer1 from '../../components/Footer1';

import { Content130DataSource } from './data.source';
import { Footer11DataSource } from '../../utils/data.source';
import './less/antMotionStyle.less';

export default class Home extends React.Component {
  render() {
    return (
      <div className="templates-wrapper" >
        <Content13
          id="Content13_0"
          key="Content13_0"
          dataSource={Content130DataSource}
          isMobile={this.props.isMobile}
        />
        <Footer1
          id="Footer1_1"
          key="Footer1_1"
          isMobile={this.props.isMobile}
          dataSource={Footer11DataSource}
        />
      </div>
    );
  }
}
