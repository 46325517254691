/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';

import Banner3 from './Banner3';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature6 from './Feature6';

import Content9 from '../../components/Content9';
import Content11 from '../../components/Content11';
import Footer1 from '../../components/Footer1';

import {
  Banner30DataSource,
  Feature10DataSource,
  Feature20DataSource,
  Feature11DataSource,
  Feature60DataSource,
} from './data.source';
import {
  Content90DataSource,
  Content110DataSource,
  Footer11DataSource,
} from '../../utils/data.source';
import './less/antMotionStyle.less';

export default class Home extends React.Component {

  render() {
    return (
      <div className="templates-wrapper" >
        <Banner3
          id="Banner3_0"
          key="Banner3_0"
          dataSource={Banner30DataSource}
          isMobile={this.props.isMobile}
        />
        <Feature1
          id="Feature1_0"
          key="Feature1_0"
          dataSource={Feature10DataSource}
          isMobile={this.props.isMobile}
        />
        <Feature2
          id="Feature2_0"
          key="Feature2_0"
          dataSource={Feature20DataSource}
          isMobile={this.props.isMobile}
        />
        <Feature1
          id="Feature1_1"
          key="Feature1_1"
          dataSource={Feature11DataSource}
          isMobile={this.props.isMobile}
        />
        <Feature6
          id="Feature6_0"
          key="Feature6_0"
          dataSource={Feature60DataSource}
          isMobile={this.props.isMobile}
        />
        <Content9
          id="Content9_0"
          key="Content9_0"
          dataSource={Content90DataSource}
          isMobile={this.props.isMobile}
        />
        <Content11
          id="Content11_0"
          key="Content11_0"
          dataSource={Content110DataSource}
          isMobile={this.props.isMobile}
        />
        <Footer1
          id="Footer1_1"
          key="Footer1_1"
          isMobile={this.props.isMobile}
          dataSource={Footer11DataSource}
        />
      </div>
    );
  }
}
