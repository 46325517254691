/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';

import Banner0 from './Banner0';
import Content3 from './Content3';
import Feature5 from './Feature5';
import Content12 from './Content12';

import Content9 from '../../components/Content9';
import Content11 from '../../components/Content11';
import Footer1 from '../../components/Footer1';

import {
  Banner01DataSource,
  Content30DataSource,
  Feature51DataSource,
  Content120DataSource,
} from './data.source';
import {
  Content90DataSource,
  Content110DataSource,
  Footer11DataSource,
} from '../../utils/data.source';
import './less/antMotionStyle.less';

export default class Home extends React.Component {
  render() {
    return (
      <div
        className="templates-wrapper"
      >
        <Banner0
          id="Banner0_1"
          key="Banner0_1"
          dataSource={Banner01DataSource}
          isMobile={this.props.isMobile}
        />
        <Content3
          id="Content3_0"
          key="Content3_0"
          dataSource={Content30DataSource}
          isMobile={this.props.isMobile}
        />
        <Feature5
          id="Feature5_1"
          key="Feature5_1"
          dataSource={Feature51DataSource}
          isMobile={this.props.isMobile}
        />
        <Content12
          id="Content12_0"
          key="Content12_0"
          dataSource={Content120DataSource}
          isMobile={this.props.isMobile}
        />
        <Content9
          id="Content9_0"
          key="Content9_0"
          dataSource={Content90DataSource}
          isMobile={this.props.isMobile}
        />
        <Content11
          id="Content11_0"
          key="Content11_0"
          dataSource={Content110DataSource}
          isMobile={this.props.isMobile}
        />
        <Footer1
          id="Footer1_1"
          key="Footer1_1"
          isMobile={this.props.isMobile}
          dataSource={Footer11DataSource}
        />
      </div>
    );
  }
}
