import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import TrialModal from '../../components/TrialModal'

class Banner extends React.PureComponent {
  // 打开申请试用modal PC版打开modal-确定后关闭modal，移动端跳转页面-确定后跳转首页
  openTrial = () => {
    sessionStorage.setItem('trialType', '客户跟进管理')
    if (this.props.isMobile) {
      window.location.href = "/Trial"
    } else {
      this.TrialRef.setIsModalOpen()
    }
  }
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item} onClick={this.openTrial}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          {children}
        </QueueAnim>
        <TrialModal ref={e => this.TrialRef = e} isMobile={this.props.isMobile} />
      </div>
    );
  }
}
export default Banner;
