import React from 'react';
import cpq_1 from '../../assets/images/CPQ/cpq_1.png'
import cpq_2 from '../../assets/images/CPQ/cpq_2.png'
import cpq_3 from '../../assets/images/CPQ/cpq_3.png'
import mp4 from '../../assets/images/Mp4/MainStage-full-subtitles.mp4'
import mp4Img from '../../assets/images/Mp4/MainStage-full-subtitles.png'
export const Banner30DataSource = {
  wrapper: { className: 'banner3 llolraks3nb-editor_css' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: (
          <span role="img" aria-label="donut">
            基于 BillingMore 明智签程™️ 数字云办公体系
          </span>
        ),
      },
      {
        name: 'slogan',
        className: 'banner3-slogan llotlytard-editor_css',
        children: 'SmartDeal CPQ 报价跟进管理解决方案',
        texty: false, // 控制children内容逐个动画效果
      },
      {
        name: 'name',
        className: 'banner3-name llotn1d3xe-editor_css',
        children: (
          <p>
            区别于传统SAAS软件，SmartDeal CPQ 是一款为提升销售工作效能而生的数字化产品，可帮助企业在线配置产品或业务组合，并创建准确、专业的报价，以快速满足客户的报价需求。
          </p>
        ),
      },
      {
        name: 'button',
        className: 'banner3-button llotnh6gz6-editor_css',
        children: (
          <p>立即试用</p>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: (
          <br />
        ),
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper llon5cuz8ge-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <p>30秒创建定制化专属报价单，一切就是那么简单</p>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: '科技想象力，金融创造力',
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: mp4,
      image: mp4Img,
    },
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5k4cw29-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: cpq_1,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>比表格更自由的产品管理配置</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        引导式路径可帮助销售在几秒钟内创建准确、专业的报价，从而快速满足客户的业务需求无论你身在何处，都可以通过任何设备进行产品或服务的配置，从而减少来回路程的时间一键查询和复制历史报价方案，减少微信报价资料翻阅时间。
      </p>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: {
    className: 'home-page-wrapper content2-wrapper llon5mkejll-editor_css',
  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: cpq_2,
  },
  textWrapper: { className: 'content2-text', md: 13, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <p>一键生成专业交互式对客报价单</p>
    ),
  },
  content: {
    className: 'content2-content',
    children: '通过极具品牌辨识度的在线报价单，最大限度地降低客户对报价的疑议，提升客户体验。同时支持 PC & 移动端，客户可通过任何设备浏览报价链接。报价可一键导出PDF，便于文件存储。',
  },
};
export const Feature11DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper llon5npwhvl-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 11, xs: 24 },
  img: {
    className: 'img-drop-shadow',
    children: cpq_3,
  },
  textWrapper: { className: 'content1-text', md: 13, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <p>客户浏览记录实时提醒，把握客户兴趣黄金时间</p>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <p>
        自动获取客户浏览时间和时长信息，帮助销售快速识别和优先处理高意向客户来产生更多收入。钉钉、企业微信、飞书、短信等多渠道客户浏览提醒，结合客户意向分析，可以帮助企业深入洞察并改善销售人员谈判、方案问题，提升签约率。
      </p>
    ),
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content llon5pdall-editor_css',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>销售流程全面赋能提升</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'X' },
              toText: true,
              children: '10',
            },
            children: { className: 'feature6-text', children: '报价效率提升10' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '95',
            },
            children: { className: 'feature6-text', children: '审批时间减少' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '%' },
              toText: true,
              children: '30',
            },
            children: { className: 'feature6-text', children: '签约率提升' },
          },
        ],
      },
    ],
  },
};
