import React from 'react';
import { Modal } from 'antd';
import Trial from '../Page/Trial'
import '../components/less/TrialModal.less'

// 申请试用modal
class TrialModal extends React.PureComponent {
  state = {
    isModalOpen: false,
  }

  // 父组件调用打开modal
  setIsModalOpen = () => {
    this.setState({
      isModalOpen: true
    })
    document.body.style.width = '100%'
    document.body.style.overflowY = 'auto'
  }

  // modal关闭回调
  onCancel = () => {
    this.setState({
      isModalOpen: false
    })
  }

  render() {
    return (
      <Modal
        title=""
        centered
        width={640}
        mask={false}
        footer={null}
        destroyOnClose
        maskClosable={false}
        className="modalClassName"
        wrapClassName={'trial_modal'}
        open={this.state.isModalOpen}
        onCancel={this.onCancel}
      >
        <Trial isMobile={this.props.isMobile} onCancel={this.onCancel}></Trial>
      </Modal>
    );
  }
}

export default TrialModal;