import React from 'react';
import icon_1 from '../../assets/images/icon/icon_1.png'
import icon_2 from '../../assets/images/icon/icon_2.png'
import icon_3 from '../../assets/images/icon/icon_3.png'
import icon_4 from '../../assets/images/icon/icon_4.png'
import icon_5 from '../../assets/images/icon/icon_5.png'
import icon_6 from '../../assets/images/icon/icon_6.png'
import BillingMore_Dark from '../../assets/images/logo/BillingMore_Dark.png'
import { TabletOutlined } from '@ant-design/icons';

export const Banner01DataSource = {
  wrapper: { className: 'banner0 llnh1d439z-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title llnh2akjwdc-editor_css',
    children: BillingMore_Dark,
  },
  content: {
    className: 'banner0-content llnh2pncrql-editor_css',
    children: (
      <span>
        <p>明智签程，数字化企业赋能解决方案</p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button llotnh6gz6-editor_css',
    children: (
      <p>了解更多</p>
    ),
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>超越中台范畴的全领域联动</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span role="img" aria-label="donut">
                BillingMore 明智签程™️
                串联各大业务模块，为企业提供超出传统中台范畴的管理赋能提升
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_1
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-green'>SmartDeal</span>
                <p>CPQ 报价跟进管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',  
            children: 'SmartDeal CPQ 是一款为提升销售工作效能而生的数字化产品，可帮助企业在线配置产品或业务组合，并创建准确、专业的报价，以快速满足客户的报价需求。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_2
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-green'>SmartDeal</span>
                <p>EContract 签约流程管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: 'SmartDeal EContract 不仅仅提供了数字化合同的自动生成，并配合企业自身可制化特性，为企业提供合同条款动态匹配、自定义审批流转、合同生命周期管理能力。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_3
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-green'>SmartDeal</span>
                <p>CRM 客户关系管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: 'SmartDeal CRM 打通了商务跟进客户的各个环节，并与飞书、企业微信等办公套件深入集成整合，不依赖人工信息录入，提供了强大的商务行为能力及客户关系管理能力。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_4
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-blue'>SmartOperation</span>
                <p>OPlan 服务流程管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: 'SmartOperation OPlan 依托SmartDeal精细化的产品SKU管理，与供应商下单、员工成就系统深度整合，为企业提供提供客户服务精细化进度管控、运营执行计划管理、异常进度即使播报的通用能力。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_5
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-blue'>SmartOperation</span>
                <p>CustoSmile 客户满意感知</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: 'SmartOperation CustoSmile 将服务进度同步、服务内容确认、过程结案报告、续约提醒、客户反馈等能力集成，并提供多端适配。为企业提供了前所未有的客户服务进度同步、客户满意度感知管理体验。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: icon_6
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <span className='content3-tag content3-tag-magenta'>SmartPivot</span>
                <p>EMP 分布式中台管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: 'SmartPivot EMP 将员工成就管理、财务管理、供应商管理、企业流程审批管理、企业自定义工作台等模块能力分布集成。以中枢管理的统筹视角，为企业中台带来全新的管理体验。',
          },
        },
      },
    ],
  },
};
export const Feature51DataSource = {
  wrapper: {
    className: 'home-page-wrapper content7-wrapper llnh5vhwiue-editor_css',
  },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <p>为企业全方位数字化赋能</p>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span role="img" aria-label="donut">
            基于 BillingMore 明智签程™️
            强大的数据流转体验，在不同数字领域皆有所为
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <p>报价管理</p>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: <TabletOutlined /> },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>比表格更自由的产品管理配置</h3>
                  <p>
                    引导式路径可帮助销售在几秒钟内创建准确、专业的报价，从而快速满足客户的业务需求无论你身在何处，都可以通过任何设备进行产品或服务的配置，从而减少来回路程的时间一键查询和复制历史报价方案，减少微信报价资料翻阅时间。
                  </p>
                  <br />
                  <h3>一键生成专业交互式对客报价单</h3>
                  <p>
                    通过极具品牌辨识度的在线报价单，最大限度地降低客户对报价的疑议，提升客户体验。同时支持 PC & 移动端，客户可通过任何设备浏览报价链接。报价可一键导出PDF，便于文件存储。
                  </p>
                  <br />
                  <h3>客户浏览记录实时提醒，把握客户兴趣黄金时间</h3>
                </span>
                <p>
                  自动获取客户浏览时间和时长信息，帮助销售快速识别和优先处理高意向客户来产生更多收入。钉钉、企业微信、飞书、短信等多渠道客户浏览提醒，结合客户意向分析，可以帮助企业深入洞察并改善销售人员谈判、方案问题，提升签约率。
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img llnik3494va-editor_css',
            children: '',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block~lljqjew6vja',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <p>签约管理</p>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: <TabletOutlined /> },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>一键生成满足企业所需的标准化合同</h3>
                <p>
                  通过标准的企业可制化合同模版，并结合可根据合同类型、售卖产品类型动态匹配合同条款规则，从而自动匹配生成满足当前企业服务所需的客制化自定义合同，极大减少了合同制作、法务审核的时间。
                </p>
                <br />
                <h3>自由的合同审核链路配置，降低合同合规审核成本</h3>
                <p>
                  企业可根据合同类型、折扣范围、付款方式、售卖产品类型等维度设定自定义的合同审批流程，针对客户需要修改条款等情况下的非标准合同，SmartDeal EContract能智能基于标准合同模版高亮所有合同修改信息，帮助审核人员快速评估合同有效性，减少低效的比对查询时间。
                </p>
                <br />
                <h3>客户浏览记录实时提醒，把握客户兴趣黄金时间</h3>
                <p>
                  自动获取客户浏览时间和时长信息，帮助销售快速识别和优先处理高意向客户来产生更多收入。钉钉、企业微信、飞书、短信等多渠道客户浏览提醒，结合客户意向分析，可以帮助企业深入洞察并改善销售人员谈判、方案问题，提升签约率。
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children: 'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block~lljqjg5fq3',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <p>客户关系管理</p>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: <TabletOutlined /> },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>直观的客户关系管理看板</h3>
                <p>
                  通过集成式的客户关系管理看板，涵盖了客户从建立开始的全部留存数据，并可根据客户意向度、预计回款时间、客情关系、客户等级等维度快速匹配筛选，并建立自定义数据分析看板，企业可以快速定位与评估当前客户池存量，并快速评估客户跟进健康度，保障商机有效推进。
                </p>
                <br />
                <h3>自由的客户关系代办、留言系统</h3>
                <p>
                  集成式的客户关系管理看板深入整合了代办留言系统，不同角色岗位可以在统一平台完成跟进提醒、续约协作工作，并可在多渠道对相关人员进行实时推送通知提醒，并提供实时反馈处理方案，在确保沟通及时的同时、也保障了对客信息的完整留存。
                </p>
                <br />
                <h3>与第三方办公系统深度整合</h3>
                <p>
                  SmartDeal CRM 实现了与飞书、企业微信、钉钉的深度整合，可匹配接入企业原有审批流程，不改变原有工作习惯，一键同步妙记录音、生成商务拜访日历，帮助企业在不额外增加使用成本的前提下，建立完整客户关系数据闭环。
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children: 'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: {
    className: 'home-page-wrapper content12-wrapper llnh5jh21wo-editor_css',
  },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children: 'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <p>知识分享</p>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhzyqpr0b-editor_css' },
          img: {
            children: 'banner0',
            className: 'llnhxh288n-editor_css',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhzvbjlz-editor_css' },
          img: {
            children: 'banner1',
            className: 'llnhxx8lb8p-editor_css',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhzrwf0k-editor_css' },
          img: {
            children: 'banner2',
            className: 'llni6rd09up-editor_css',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhyu3o8q7-editor_css' },
          img: {
            children: 'banner3',
            className: 'llni6t0fhw-editor_css',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhy97gsx-editor_css' },
          img: {
            children: 'banner4',
            className: 'llni6umbgi8-editor_css',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        xs: 24,
        sm: 12,
        md: 8,
        children: {
          wrapper: { className: 'block-content llnhyeszduc-editor_css' },
          img: {
            children: 'banner5',
            className: 'llni6vwwjor-editor_css',
          },
        },
      },
    ],
  },
};
