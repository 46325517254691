import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import TrialModal from '../../components/TrialModal'

class Banner extends React.PureComponent {
  // 打开申请试用modal PC版打开modal-确定后关闭modal，移动端跳转页面-确定后跳转首页
  openTrial = () => {
    sessionStorage.setItem('trialType', '了解更多')
    if (this.props.isMobile) {
      window.location.href = "/Trial"
    } else {
      this.TrialRef.setIsModalOpen()
    }
  }

  render() {
    const { ...currentProps} = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="title" {...dataSource.title}>
            <img src={dataSource.title.children} width="100%" alt="img" />
          </div>
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <Button ghost key="button" {...dataSource.button} onClick={this.openTrial}>
            {dataSource.button.children}
          </Button>
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
        <TrialModal ref={e => this.TrialRef = e} isMobile={this.props.isMobile} />
      </div>
    );
  }
}
export default Banner;
