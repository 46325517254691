const api = {
  SaveCpqCompany: '/CustomerQuotation/SaveCpqCompany', // 申请试用-提交
  GetPageNewsList: '/Website/GetPageNewsList', // 获取新闻list
  GetNewsById: '/Website/GetNewsById/', // 获取新闻详情
}

if (process.env.NODE_ENV !== 'production') {
  // api.shoomeOAUrl = 'http://192.168.8.11:7781/api/V1'
  api.shoomeOAUrl = 'https://api.oa.shoome.cn/api/V1'
} else {
  api.shoomeOAUrl = 'https://api.oa.shoome.cn/api/V1'
}

export default api
